<template>
   <v-row>
    <v-col cols="12">
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Recordatorios de pago</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <common-templates kind="collection" />
        </v-card-text>
      </v-card>
      <v-skeleton-loader class="mt-5" v-if="$store.state.reminders.retrieveLoader" transition="fade-transition" type="article" />
      <template v-else>
        <v-card class="mt-5">
          <v-toolbar color="transparent" flat height="55">
            <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Reglas del envío de recordatorios automáticos</v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <v-card-text class="py-8">
            <template v-if="templatesList.length">
              <v-row no-gutters>
                <v-switch v-model="sendPaymentsReminders" @change="updateAccount" :disabled="!rulesList.filter(rule => rule.id).length" class="py-0 my-0" :width="32" :height="18" hide-details />
                <span class="d-block body-2 text--secondary font-weight-medium">Activar los recordatorios automáticos</span>
                <template v-if="!rulesList.filter(rule => rule.id).length">
                  <v-icon class="ml-8" color="red">mdi-alert-circle</v-icon><span class="text--red ml-1">Antes de activar las reglas de envío debes tener al menos una regla creada</span>
                </template>
              </v-row>
              <v-row class="py-4">
                <v-col cols="12" v-for="(d, i) in rulesList" :key="i">
                  <div class="d-flex">
                    <div class="mr-3" style="width:80px">
                      <v-text-field v-model="d.days" :error="$v.rulesList.$each[i].days.$error" outlined required single-line reverse dense hide-details />
                    </div>
                    <v-row class="defaultGrey text--secondary mx-2 ml-0" style="border-radius: 4px; max-width: 90px; height: 40px;" align="center" no-gutters justify="center">días</v-row>
                    <div class="mx-2" style="width:130px">
                      <v-select v-model="d.scheme" :error="$v.rulesList.$each[i].days.$error" :items="scheme" single-line :v-ripple="false"  item-text="text" @change="setTipo(i)" :disabled="i > 0 && rulesList[i - 1].scheme === 'D'" item-value="value" hide-details dense height="30" outlined />
                    </div>
                    <v-row class="defaultGrey text--secondary mx-2" style="border-radius: 4px; max-width: 200px; height: 40px;" align="center" no-gutters justify="center">del vencimiento enviar</v-row>
                    <div class="mx-2" style="width:180px">
                      <v-select v-model="d.template" :error="$v.rulesList.$each[i].days.$error" :items="templatesList" single-line :v-ripple="false" item-text="name" item-value="id" hide-details dense height="30" outlined />
                    </div>
                    <v-row class="defaultGrey text--secondary mx-2" style="border-radius: 4px; max-width: 140px; height: 40px;" align="center" no-gutters justify="center">como mensaje</v-row>
                    <div class="mx-2" style="width:80px" v-if="!d.id">
                      <v-btn @click="postRule(i)" outlined height="40">Guardar</v-btn>
                    </div>
                    <div class="mx-2" style="width:100px">
                      <v-btn v-if="i >= 1 || rulesList.length > 1" @click="deleteRule(i)" icon color="red"><v-icon small>mdi-close</v-icon></v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col class="py-0" cols="12">
                  <v-btn @click="addRowReminderRules" :disabled="rulesList.length >= 5" text color="primary" :ripple="false"><v-icon left>mdi-plus</v-icon>Crear regla</v-btn>
                </v-col>
              </v-row>
            </template>
            <v-row class="py-8" v-else>
              <v-col class="text-center" cols="12">
                <v-icon color="red">mdi-alert-circle</v-icon><span class="body-2 ml-1">Antes de configurar las reglas de envío debes tener al menos un recordatorio creado</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import CommonTemplates from '@/modules/account/views/CommonTemplates'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import cloneDeep from 'lodash/cloneDeep'
export default {
  components: {
    CommonTemplates
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    sendPaymentsReminders: false,
    newRules: [],
    scheme: [
      { text: 'Antes', value: 'B' },
      { text: 'Después', value: 'A' }
    ]
  }),
  computed: mapState({
    accountsDetail: state => state.accounts.accountsDetail,
    templatesList: state => cloneDeep(state.reminders.templatesList),
    rulesList: state => cloneDeep(state.reminders.rulesList)
  }),
  created () {
    this.retrieveAccount()
    this.getTemplates()
  },
  methods: {
    retrieveAccount () {
      this.$store.dispatch('accounts/RETRIEVE', {
        resource: 'accounts',
        id: this.$store.state.auth.account.id
      })
      .then(() => {
        this.getRules()
        this.sendPaymentsReminders = this.accountsDetail.send_payments_reminders
      })
    },
    getTemplates () {
      this.$store.dispatch('reminders/LIST', {
        resource: 'reminders/templates',
        query: {
          kind: 'collection'
        }
      })
    },
    getRules () {
      this.$store.dispatch('reminders/LIST', {
        resource: 'reminders/rules'
      })
      .then(() => {
        if (!this.rulesList.length) {
          this.addRowReminderRules()
        }
      })
    },
    postRule (i) {
      this.$v.rulesList.$each[i].$touch()
      if (this.$v.rulesList.$each[i].$invalid) {
        return false
      }
      this.$store.dispatch('reminders/CREATE', {
        resource: 'reminders/rules',
        payload: this.rulesList[i]
      })
      .then(() => {
        this.$dialog.message.info('La regla ha sido guardada')
        this.$store.dispatch('reminders/DESTROY_TEMPORAL_OBJECT', i)
      })
    },
    patchRule (i) {
      this.$v.rulesList.$each[i].$touch()
      if (this.$v.rulesList.$each[i].$invalid) {
        return false
      }
      const payload = {
        days: this.rulesList[i].days,
        scheme: this.rulesList[i].scheme,
        template: this.rulesList[i].template
      }
      this.$store.dispatch('reminders/UPDATE', {
        resource: 'reminders/rules',
        id: this.rulesList[i].id,
        payload: payload
      })
    },
    deleteRule (i) {
      if (!this.rulesList[i].id) {
        this.$store.dispatch('reminders/DESTROY_TEMPORAL_OBJECT', i)
      } else {
          this.$dialog.warning({
          title: 'Eliminar la regla',
          text: `
            <span class="d-block mb-4">Al confirmar se eliminará la regla de tu cuenta y todo lo asociado con esta utilizará un mensaje por defecto.</span>
          `,
          actions: [
            {
              color: 'red',
              text: 'Continuar',
              handle: () => {
                this.$store.dispatch('reminders/DESTROY', {
                  resource: 'reminders/rules',
                  id: this.rulesList[i].id
                })
                .then((response) => {
                  this.$dialog.message.info('La regla ha sido eliminada')
                })
                .catch((error) => {
                  this.$dialog.message.error(this.parseErrors(error.response.data))
                })
              }
            }
          ]
        })
      }
    },
    updateAccount () {
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'accounts',
        payload: {
          send_payments_reminders: this.sendPaymentsReminders
        },
        id: this.account.id
      })
      .then(() => {
        this.$dialog.message.info(`Se han ${this.sendPaymentsReminders ? 'habilitado' : 'deshabilitado'} los recordatorios automáticos de pagos`)
      })
      .catch(() => {
        this.$dialog.message.warning(`Error al ${this.sendPaymentsReminders ? 'habilitado' : 'deshabilitado'} los recordatorios automáticos de pagos`)
      })
    },
    addRowReminderRules () {
      this.$store.dispatch('reminders/ADD_TEMPORAL_OBJECT', {
        days: null,
        scheme: 'A',
        template: this.templatesList[0].id
      })
    }
  },
  validations: {
    rulesList: {
      $each: {
        days: {
          required,
          numeric
        },
        scheme: {
          required
        },
        template: {
          required
        }
      }
    }
  }
}
</script>